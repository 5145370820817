
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    mixins: [ScreenSize],
    props: {
      title: {
        type: String,
        default: undefined,
      },
      headlineType: {
        type: String,
        default: 'h2_styled_gold',
      },
    },
    data() {
      return {
        changedTitle: '',
        mainTitle: '',
        secondTitle: '',
        tag: '',
        styling: '',
        coloring: '',
        headline: '',
      };
    },
    watch: {
      title: function (newVal) {
        this.createTitle(newVal);
      },
    },
    created() {
      this.headline =
        this.headlineType === null || this.headlineType === 'none'
          ? 'h2_styled_gold'
          : this.headlineType;
      this.tag = this.headline.split('_')[0];
      this.styling = this.headline.split('_')[1];
      this.coloring = this.headline.split('_')[2];

      // split title in two parts
      if (this.title) {
        this.createTitle(this.title);
      }
    },
    methods: {
      createTitle(title) {
        const titleWords = title.trim().split(' ');
        this.mainTitle = titleWords[0];
        this.secondTitle = this.title.substring(this.mainTitle.length).trim();
      },
    },
  };
